<template>
  <div>
    <v-card flat tile>
      <v-card-title class="text-lg-h4 text-lx-h4 text-sm-h6 text-md-h5"
        ><span>Расписание</span>
        <v-divider class="ml-5 mr-5" inset vertical></v-divider>
        <span
          class="
            text-lg-h6 text-lx-h6 text-sm-caption text-md-body-1 text-body-2
          "
          >{{ title }}</span
        >
        <v-spacer></v-spacer>
        <v-btn
          :x-small="$vuetify.breakpoint.xs"
          :small="$vuetify.breakpoint.sm"
          v-if="!title_focus"
          outlined
          color="success"
          @click="Today"
        >
          Сегодня
        </v-btn>
        <v-btn
          v-if="
            $vuetify.breakpoint.lg ||
            $vuetify.breakpoint.xl ||
            $vuetify.breakpoint.md
          "
          icon
          @click="prev"
        >
          <v-icon color="success" small>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          v-if="
            $vuetify.breakpoint.lg ||
            $vuetify.breakpoint.xl ||
            $vuetify.breakpoint.md
          "
          icon
          @click="next"
        >
          <v-icon color="success" small>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-title>
    </v-card>
    <v-row v-touch="{ left: () => next(), right: () => prev() }">
      <v-card width="100%" :loading="x_loading" tile flat>
        <v-calendar
          locale="ru-RU"
          @click:event="EventClick"
          v-model="focus"
          :weekdays="[1, 2, 3, 4, 5, 6]"
          ref="weekly"
          :events="events"
          type="week"
          first-time="08:00"
          interval-count="14"
          interval-height="40"
          color="success"
          class="calendar_card"
        >
          <template v-slot:event="{ event }" class="event">
            <div class="event">
              <div :class="`${event.color.split(' ')[0]} right-line`"></div>
              <v-list-item v-if="$vuetify.breakpoint.width > 667">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">{{
                    event.name
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    :class="`${
                      event.color.split(' ')[0]
                    }--text font-weight-bold`"
                    >комната {{ event.room }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <div v-else>
                <div class="group_name">
                  {{ event.name.split(" ")[0] }}
                </div>
                <div :class="`group_name ${event.color.split(' ')[0]}--text`">
                  {{ event.name.split(" ")[1] }}
                </div>
              </div>
            </div>
          </template>
        </v-calendar>
      </v-card>
    </v-row>
    <v-dialog scrollable width="650" v-model="eventDialog">
      <v-card v-if="selectedEvent">
        <v-card-title
          :class="`${selectedEvent.color.split(' ')[0]} white--text`"
          >{{ selectedEvent.name }}<v-spacer></v-spacer>
          <v-icon color="white" @click="eventDialog = false"
            >mdi-close</v-icon
          ></v-card-title
        >
        <v-card-text>
          <v-subheader>Учитель</v-subheader>
          <v-list-item>
            <v-list-item-avatar>
              <v-img
                height="100%"
                :src="api + selectedEvent.group.teacher.user.image"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ selectedEvent.group.teacher.user.first_name }}
                {{ selectedEvent.group.teacher.user.last_name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-card-text v-if="selectedEvent.group.students.length">
          <v-subheader>Ученики</v-subheader>
          <v-list-item
            v-for="student in selectedEvent.group.students"
            :key="student.id"
          >
            <v-list-item-avatar>
              <v-img height="100%" :src="api + student.user.image"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ student.user.first_name }}
                {{ student.user.last_name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { LESSONS_GET } from "@/store/actions/other";
export default {
  data() {
    return {
      selectedEvent: null,
      events: [],
      eventDialog: false,
      title: "",
      focus: "",
      title_focus: true,
      api: process.env.VUE_APP_API_URL.slice(0, -1),
    };
  },
  methods: {
    async LoadSchedule() {
      let resp = await this.$dispatch(
        LESSONS_GET,
        `?start_time_after=${moment(this.focus)
          .startOf("week")
          .format("YYYY-MM-DD")}&start_time_before=${moment(this.focus)
          .endOf("week")
          .format("YYYY-MM-DD")}`
      );
      if (resp.success) {
        let event = [];
        for (let e in resp.result) {
          resp.result[e].start = resp.result[e].start_time;
          resp.result[e].end = resp.result[e].end_time;
          resp.result[e].name = resp.result[e].group.number;
          resp.result[e].timed = true;
          resp.result[e].color = this.GetEventColor(resp.result[e]);
          event.push(resp.result[e]);
        }
        this.events = event;
      } else {
        alert(resp);
      }
    },
    GetEventColor(event) {
      switch (event.group.direction.name) {
        case "Full Stack Python":
          return "primary lighten-5";
        case "AI":
          return "yellow lighten-5";
        case "Graphic Design":
          return "red lighten-5";
      }
    },
    EventClick({ event }) {
      this.selectedEvent = event;
      this.eventDialog = true;
    },
    Today() {
      this.focus = moment().format("YYYY-MM-DD");
      this.title = moment().format("MMMM YYYY");
      this.TitleFocus();
      this.LoadSchedule();
    },
    async prev() {
      await this.$refs.weekly.prev();
      this.title = this.$refs.weekly.title;
      this.TitleFocus();
      this.LoadSchedule();
    },
    async next() {
      await this.$refs.weekly.next();
      this.title = this.$refs.weekly.title;
      this.TitleFocus();
      this.LoadSchedule();
    },
    TitleFocus() {
      if (moment(this.focus).week() === moment().week()) {
        this.title_focus = true;
      } else {
        this.title_focus = false;
      }
    },
    get_empty_2d_array(numRows, numColumnns) {
      return [...Array(numRows)].map((e) => Array(numColumnns));
    },
  },
  created() {
    moment.locale("ru");
    this.Today();
    this.LoadSchedule();
  },
};
</script>

<style scoped>
.event {
  height: 100% !important;
}
.right-line {
  float: right !important;
  height: 100% !important;
  width: 8px !important;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.group_name {
  overflow: hidden;
  font-size: 12px;
  color: black;
}
</style>